<template>
  <div class="sub-menu">
    <div class="nav-bar">
      <div class="menu-links">
        <div
          class="menu-link"
          v-if="getPermissionInterface('Simulation Facture Frais')"
        >
          <router-link
            class="menu-item"
            to="/frais-th/simulation-facture-frais"
          >
            Simulation facture
          </router-link>
        </div>
      </div>
      <div class="menu-links">
        <div class="menu-link" v-if="getPermissionInterface('Frais BDD')">
          <router-link class="menu-item" to="/frais-th/bdd">
            BDD
          </router-link>
        </div>
      </div>
      <div class="menu-links">
        <div
          class="menu-link"
          v-if="getPermissionInterface('Récapitulatif gestion des frais')"
        >
          <router-link class="menu-item" to="/frais-th/vision-filiale">
            Gestion global des frais
          </router-link>
        </div>
      </div>
      <div class="menu-links">
        <div
          class="menu-link"
          v-if="getPermissionInterface('Gestion Commercial Cactus')"
        >
          <router-link class="menu-item" to="/frais-th/comercial-cactus">
            Gestion Commercial Cactus
          </router-link>
        </div>
      </div>
      <!-- <div class="menu-links">
        <div class="menu-link">
          <router-link class="menu-item" to="/frais-th/comercial-cactus">
            Gestion des variables responsable filiale
          </router-link>
        </div>
      </div> -->
      <div class="menu-links">
        <div
          class="menu-link"
          v-if="getPermissionInterface('Gestion Bureau Etude')"
        >
          <router-link class="menu-item" to="/frais-th/bureau-etude">
            Gestion Bureau d'etude
          </router-link>
        </div>
      </div>
      <div class="menu-links">
        <div class="menu-link" v-if="getPermissionInterface('Gestion Compta')">
          <router-link class="menu-item" to="/frais-th/compta">
            Gestion Compta
          </router-link>
        </div>
      </div>
      <div class="menu-links">
        <div class="menu-link" v-if="getPermissionInterface('Gestion RH')">
          <router-link class="menu-item" to="/frais-th/rh">
            Gestion Rh
          </router-link>
        </div>
      </div>
      <!-- <div class="menu-links">
        <div class="menu-link" v-if="getPermissionInterface('Gestion Invest')">
          <router-link class="menu-item" to="/frais-th/energie-invest">
            Gestion Loyer EI - SCI
          </router-link>
        </div>
      </div> -->
      <div class="menu-links">
        <div
          class="menu-link"
          v-if="getPermissionInterface('Gestion informatique web abondance')"
        >
          <router-link class="menu-item" to="/frais-th/web-abondance">
            Gestion Informatique WA
          </router-link>
        </div>
      </div>
      <div class="menu-links">
        <div
          class="menu-link"
          v-if="getPermissionInterface('Gestion Interne IHS')"
        >
          <router-link class="menu-item" to="/frais-th/interne-ihs">
            Interne IHS
          </router-link>
        </div>
      </div>
    </div>
    <div class="body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['getPermissionInterface', 'getUserData']),
    isResponsableRh() {
      return (
        this.getUserData?.role && this.getUserData.role == 'responsable_ge_rh'
      );
    },
    isRsponsableCompta() {
      return (
        this.getUserData?.role &&
        this.getUserData.role == 'responsable_ge_compta'
      );
    }
  }
};
</script>

<style scoped lang="scss"></style>
